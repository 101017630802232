import React from 'react';
import landing from '../../images/section-landing.svg';
import './landing.scss';

const Landing = () => (
  <section className="landing">
    <div>
      <img src={landing} alt="logo" />
    </div>
    <div>
      <p>AI for online shops</p>
      <p>affordable and simple</p>
    </div>
  </section>
);

export default Landing;
