import React from 'react';
import audreyDialog from '../../images/audrey-illustration.svg';
import audreyIcon from '../../images/audrey-icon.svg';
import './audrey.scss';

const Audrey = () => (
  <section className="audrey" id="audrey">
    <div className="audrey__left">
      <div className="top-box">
        <img src={audreyIcon} alt="audrey"></img>
        <div>
          <h2>Audrey</h2>
          <h4>AI Shop Assistant</h4>
        </div>
      </div>
      <p>
        By leveraging the AI capability to understand human language, AI
        chatbots enhance the customer support in a naturally interactive manner
        with very low cost. They give customers a quick and convenient way to
        get answers for their requests.
      </p>
      <a href="https://apps.shopify.com/audrey">See more</a>
    </div>
    <div className="audrey__right">
      <div>
        <img src={audreyDialog} alt="audrey-dialog"></img>
      </div>
    </div>
  </section>
);

export default Audrey;
