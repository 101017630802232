import React from 'react';
import glazfitImg from '../../../images/section-products-glazfit.svg';
import ezicompareImg from '../../../images/section-products-ezicompare.svg';
import visitStoreImg from '../../../images/section-products-more.svg';
import './products.scss';
import Product from './product';

const Products = () => (
  <section className="products" id="products">
    <a name="products"></a>
    <Product
      className="products__glazfit"
      heading="Glazfit"
      paragraph="The easiest solution to bring the virtual try-on to your online store"
      src={glazfitImg}
      link="https://glazfit.com/"
      linkText="See more"
    />
    <Product
      className="products__ezicompare"
      heading="EziCompare"
      paragraph="Handy products comparison app with configurable recommendation"
      src={ezicompareImg}
      link="https://apps.shopify.com/ezicompare"
      linkText="See more"
    />
    <Product
      className="products__visit-store"
      heading=""
      paragraph="Want to know about other AI products?"
      src={visitStoreImg}
      link="https://apps.shopify.com/partners/developer-8d7ce69904346859"
      linkText="Visit apps store"
    />
  </section>
);

export default Products;
