import React from 'react';
import technology from '../../images/section-technology.svg';
import './technology.scss';

const Technology = () => (
  <section className="technology" id="technology">
    <a name="technology"></a>
    <div>
      <img src={technology} alt="technology"></img>
    </div>
    <div>
      <p>
        AI has the power to help e-commerce businesses perform better. It
        provides customers with more efficient, more enjoyable and more
        personalized shopping experiences while increasing sales and reducing
        cost.
      </p>
      <ul>
        <li>
          <a href="https://apps.shopify.com/audrey">Human-like AI chatbot</a>
        </li>
        <li>
          <a href="https://apps.shopify.com/bargainer">
            Automated price negotiation
          </a>
        </li>
        <li>AI-powered site search</li>
        <li>Intelligent product recommendations</li>
      </ul>
    </div>
  </section>
);

export default Technology;
