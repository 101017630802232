import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Landing from '../components/home/landing';
import Audrey from '../components/home/audrey';
import Technology from '../components/home/technology';
import Products from '../components/home/products';
import '../styles/_base.scss';

const IndexPage = () => (
  <Layout>
    <SEO title="Mevry | AI for online shops" />

    <Landing />

    <Technology />

    <Audrey />

    <Products />
  </Layout>
);

export default IndexPage;
