import React from 'react';

const Product = ({ heading, paragraph, src, className, link, linkText }) => (
  <div className={className}>
    <h2>{heading}</h2>
    {paragraph && <p>{paragraph}</p>}
    <a href={link}>{linkText}</a>
    <img src={src} alt="product" />
  </div>
);

export default Product;
